<template>
  <div class="bg bg4">
    <div class="class--con">
      <class-but @forward="forward"/>
      <div class="class--container">
        <div class="colour">到课率</div>
        <div class="colour">
          <span>班级到课率：{{ coursePercentList.percent }}%</span>
          <span class="colour--annotation">兴趣是最好的老师，学习贵在持之以恒。</span>
        </div>

        <div style="display: flex; ">
          <div class="student">
            <div class="shuli">学生到课率</div>
          </div>
<!--          <div style="display: flex;width: 100%;align-items: center;justify-content: center;margin-top: 30px">-->
<!--            <div class="percentage">-->
<!--              <div class="percentage&#45;&#45;list" v-for="(i,k) in coursePercentList.studentInfo" :key="k">-->
<!--                <div class="percentage__name">{{ i.studentName }}</div>-->
<!--                <div class="percentage&#45;&#45;data">-->
<!--                  <a-progress :stroke-width="36" :percent="i._percent" :stroke-color="{from: '#FFA024', to: '#FF7846'}" :format="percent=>percent + '%'"></a-progress>-->
<!--                  <img :src="menu2" alt="" v-if="i._percent === 100">-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        <div class="ProgressBarContainer">
          <div class="ProgressBarContainer_item" v-for="(i,k) in coursePercentList.studentInfo" :key="k">
            <div style="color: #FF7846">{{i._percent}}%</div>
            <div class="ProgressBarContainer_bg">
              <div class="progress" :style="{height:i._percent*3+'px'}"></div>
            </div>
            <div style="color:#525353;">{{i.studentName}}</div>
          </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import menu2 from "@/assets/image/conference/icon_huanguan.png"
import ClassBut from "./classBut";
import service from "@/utils/axios";
import {Progress} from 'ant-design-vue'

export default {
  name: "eightPage",
  components: {
    ClassBut,
    AProgress: Progress,
  },
  data() {
    return {
      menu2: menu2,
      coursePercentList: {}

    }
  },
  created() {
    this.gitApicoursePercent()
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'PageEleventh',
        query: this.$route.query,
      })
    },
    async gitApicoursePercent() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/coursePercent`, {
        classId: this.$route.query.classId,
      })

      res.studentInfo.sort(function (a, b) {
        return b._percent - a._percent
      })
      this.coursePercentList = res
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 45px 90px;
  box-sizing: border-box;
}

.colour {
  color: #2B3654;
  font-size: 42px;
  font-weight: bold;
  display: flex;
  align-items: center;

  .colour--annotation {
    width: 708px;
    height: 66px;
    background: #FFEEDB;
    border-radius: 4px;
    display: inline-block;
    color: #FC5832;
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    line-height: 66px;
    margin-left: 44px;
  }
}

.student {
  height: 500px;
  /*width: 50px;*/
  display: flex;
  align-items: center;
  justify-items: center;

  .shuli {
    width: 20px;
    line-height: 35px;
    color: #2B3654;
    font-size: 28px;
    font-weight: bold;
  }
}
.ProgressBarContainer{
  background: #FFFFFF;
  height: 427px;
  width: 100%;
  margin-top: 34px;
  margin-left: 39px;
  display: flex;
  .ProgressBarContainer_item{
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    .ProgressBarContainer_bg{
      width: 25px;
      height: 300px;
      background: #FAF6E0;
      border-radius: 21px;
      margin: 10px 0;
      position: relative;

      .progress{
        width: 25px;
        background: #FAB721;
        border-radius: 21px;
        text-align: start;
        position: absolute;
        bottom: 0;
        max-height: 300px;
      }
    }
  }
}

.percentage {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(62, 62, 62, 0.16);
  height: 570px;
  width: 775px;
  border-radius: 10px;
  margin-top: 10px;
  overflow-y: auto;

  .percentage--list {
    width: 775px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100px;
  }

  .percentage__name {
    color: #525353;
    font-size: 36px;
    width: 291px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .percentage--data {
    width: 490px;
    /*border: 1px solid red;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

:deep(.ant-progress-outer) {
  width: 400px;
}

:deep(.ant-progress-bg) {
  border-radius: 0!important;
  border-top-right-radius: 100px!important;
  border-bottom-right-radius: 100px!important;
}
:deep(.ant-progress-inner){
  border-radius: 0!important;
  border-top-right-radius: 100px!important;
  border-bottom-right-radius: 100px!important;
}
:deep(.ant-progress-text){
  color: #606266;
  font-size: 26px;
}

::-webkit-scrollbar {
  display: none;
}

</style>
